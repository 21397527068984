.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* Start content from the top */
  align-items: center;
  min-height: 100vh;
}

/* Style for the navbar */
.Header {
  /* Add your styles for the navbar here */
  height: 50px;
  /* Set the desired height for your navbar */
}
